import React from "react";
import GatsbyLink from "../GatsbyLink";
import "./Button.scss";

export const Button = ({ buttonTheme, buttonText, buttonLink, buttonIcon }) => {
  return (
    <div className="button">
      {buttonLink && (
        <GatsbyLink
          to={buttonLink}
          className={`button-component button-component--${buttonTheme}  ${buttonIcon} `}
        >
          {buttonText}
        </GatsbyLink>
      )}
    </div>
  );
};
