import React from "react";
import GatsbyLink from "../../GatsbyLink";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import { Button } from "../../Button";
import "./PostListItem.scss";
import { decodeEntities } from "../../../utils/helpers";
import { dateI18n } from "@wordpress/date";

export default (props) => {
  const { siteMetadata, data, pathPrefix, showAuthor, dateFormat } = props;
  const { excerpt, title, slug, path, featured_media, author, date } = data;
  const dateFormatted = dateFormat && date && dateI18n(dateFormat, date);
  return (
    <div className="post-list-item">
      <div>
        <div className="image">
          <GatsbyLink to={path} label={title}>
            <ImagePass src={featured_media} className="background" />
          </GatsbyLink>
        </div>
        <div className="inner">
          <h4 className="title">
            <GatsbyLink to={path}>{decodeEntities(title)}</GatsbyLink>
          </h4>
          {excerpt && <RenderContent content={`${excerpt}`} />}
        </div>
        <div className="button-wrapper">
          {slug && (
            <Button
              className="post-link"
              buttonLink={path}
              buttonText="View Project"
              buttonTheme="red"
              buttonIcon="arrow-right"
            />
          )}
        </div>
        <div className="meta">
          {showAuthor && (
            <div className="avatar">
              <ImagePass src={author.avatar_urls.wordpress_48} />
            </div>
          )}
          <div>
            {author && showAuthor && (
              <GatsbyLink to={author.path} className="author">
                {author.name}
              </GatsbyLink>
            )}
            {dateFormatted && <span className="date">{dateFormatted}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};
